import * as Sentry from '@sentry/browser';
import { APP_VERSION, ENVIRONMENT, SENTRY_DSN } from 'utils/constants';
import { instanceId } from 'service/metrics.service';

export const initSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: APP_VERSION,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      beforeSend(event) {
        event.extra = {
          ...event.extra,
          instanceId,
        };

        return event;
      },
    });
  }
};

export const setUser = ({ id, email }: { id: string; email: string }) => {
  Sentry.configureScope(scope => {
    scope.setUser({ id, email });
  });
};
