import { createContext, useContext } from 'react';

export interface IframeContext {
  orderLink?: string;
  isCustomerDashboardLinkDisabled?: boolean;
  isIframeIssue?: boolean;
  walletsUrl?: string;
  isWalletListProvidedByIframe?: boolean;
  isOrderSearchIframe?: boolean;
  isPunchingOrderIframe?: boolean;
}

const IframeContext = createContext<Partial<IframeContext>>({});

const IframeProvider = ({ children, ...props }: React.PropsWithChildren<IframeContext>) => {
  return <IframeContext.Provider value={{ ...props }}>{children}</IframeContext.Provider>;
};

export const useIframeContext = () => {
  const context = useContext(IframeContext);
  if (!context) {
    throw new Error('useIframeContext must be used under IframeProvider');
  }
  return context;
};

export default IframeProvider;
